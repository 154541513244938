




















































import marked from "marked";
import Vue from "vue";
import axios from "axios";
let rendererMD = new marked.Renderer();
marked.setOptions({
  renderer: rendererMD,
  gfm: true,
  breaks: false,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: false,
});
rendererMD.heading = function (text, level) {
  return `<h${level} style="margin-bottom:20px;margin-top:20px">${text}</h${level}>`;
};
rendererMD.link = function (href, title, text) {
  return `<a style="color:#4183c4;text-decoration: none;" href=${href}>${text}</a>`;
};
export default Vue.extend({
  data: () => ({
    group: [] as any,
    text: ``,
    papers: [] as any,
    paperNum: 0,
    articles: [] as any,
    articleNum: 0,
  }),
  computed: {
    markdown: function () {
      return marked(this.text);
    },
  },
  mounted: function () {
    // 获取当前组
    let name = this.$route.name ? this.$route.name : "1";
    if (name.startsWith("fe")) {
      this.group = 1;
    } else if (name.startsWith("asr")) {
      this.group = 2;
    } else if (name.startsWith("tts")) {
      this.group = 3;
    } else if (name.startsWith("sv")) {
      this.group = 4;
    } else {
      this.group = 0;
    }
    // 获取介绍
    axios
      .post(Vue.prototype.serverAddr + "/api/get-item", {
        collection: "intro",
        need: {
          index: this.group,
        },
      })
      .then((response) => {
        let tmp = (response.data as any)[0].content;
        tmp = tmp.replace(/localhost/g, Vue.prototype.serverAddr)
        this.text = tmp;
      });
    // 获取论文总数与论文列表
    axios
      .post(Vue.prototype.serverAddr + "/api/get-item-num", {
        collection: "achievement",
        need: {
          group: this.group,
          type:0
        },
      })
      .then((response) => {
        console.log("get response");
        this.paperNum = (response.data as any).num;
      });
    this.getPaperList();
    // 获取成果推介总数与论文列表
    axios
      .post(Vue.prototype.serverAddr + "/api/get-item-num", {
        collection: "article",
        need: {
          type: "recommend",
          group: this.group,
        },
      })
      .then((response) => {
        console.log("get response");
        this.articleNum = (response.data as any).num;
      });
    this.getArticleList();
  },
  methods: {
    getPaperList() {
      axios
        .post(Vue.prototype.serverAddr + "/api/get-item-list", {
          collection: "achievement",
          need: {
            group: this.group,
            type:0
          },
          page: 1,
          numInOnePage: this.paperNum,
        })
        .then((response) => {
          console.log("get response");
          this.papers = response.data;
        });
    },
    getArticleList() {
      axios
        .post(Vue.prototype.serverAddr + "/api/get-item-list", {
          collection: "article",
          need: {
            type: "recommend",
            group: this.group,
          },
          page: 1,
          numInOnePage: this.articleNum,
        })
        .then((response) => {
          console.log("get response");
          this.articles = response.data;
        });
    },
    gotoArticle: function (type: string, id: string, link: string, content:string) {
      if (link != "") {
        window.location.href = link;
      } else {
        if (type == "recommend") {
          this.$router.push({
            path: `/recommend/${id}`,
          });
          console.log(id);
        } else if (content !== "") {
          this.$router.push({
            path: `/article/${type}/${id}`,
          });
          console.log(id);
        }
      }
    },
  },
});
